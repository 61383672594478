<template>
  <div id="sidebar">
    <nav class="container-sidebar">
      <div>
        <div class="container-siderbar-itens">
          <ul class="menu">
            <li>
              <LinkSidebar :texto="'Home'" :link="'/home'" :icone="'IconInicio'" />
            </li>
            <div v-if="perfilPainel == 2 || perfilPainel == 5">
              <li>
                <LinkSidebar :texto="'Usuários'" :link="'/usuarios'" :icone="'IconUsuarios'" />
              </li>
              <li>
                <LinkSidebar :texto="'Autorizadas'" :link="'/autorizadas'" :icone="'IconClientes'" />
              </li>
              <li class="drop-menu campanhas" ref="campanhas">
                <p class="link-sidebar" @click="menuDropDown('campanhas')">
                  <span class="seta titulo-drop"></span>
                  <span class="link-texto">Cadastro</span>
                </p>
                <ul class="submenu">
                  <li v-for="link in itemMenuInterno" :key="link.id">
                    <LinkSidebar v-if="menuCadastro(link.path)" :texto="link.name" :link="link.path"
                      :icone="link.meta.menuInterno.icone" />
                  </li>
                </ul>
              </li>
              <li class="drop-menu processamento" ref="processamento">
                <p class="link-sidebar" @click="menuDropDown('processamento')">
                  <span class="seta titulo-drop"></span>
                  <span class="link-texto">Processamento</span>
                </p>
                <ul class="submenu">
                  <li v-for="link in itemMenuInterno" :key="link.id">
                    <LinkSidebar v-if="menuProcessamento(link.name)" :texto="link.name" :link="link.path"
                      :icone="link.meta.menuInterno.icone" />
                  </li>
                </ul>
              </li>
            </div>
            <li v-if="perfilPainel == 4 || perfilPainel == 2 || perfilPainel == 5">
              <LinkSidebar :texto="'SAC'" :link="'/sac'" :icone="'IconSac'" />
            </li>
            <li v-if="perfilPainel == 3 || perfilPainel == 2 || perfilPainel == 5">
              <LinkSidebar :texto="'Relatórios'" :link="'/powerbi'" :icone="'IconRelatorioResgate'" />
            </li>
            <li v-if="perfilPainel == 2">
              <LinkSidebar :texto="'Gerenciamento'" :link="'/gerenciamento-sistema'" :icone="'IconConfig'" />
            </li>
          </ul>
        </div>
        <div class="menu-lateral-btn" @click="toggleMenu" :class="{ 'menu-lateral-fechado': !menuAtivo }">
          <span></span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import LinkSidebar from "@/components/estrutura/sidebar/LinksSidebar.vue";
import store from "@/store";
import { mapState } from "vuex";
export default {
  components: {
    LinkSidebar,
  },
  data() {
    return {
      perfilPainel: parseInt(localStorage.getItem('perfil'))
    };
  },
  computed: {
    ...mapState({
      perfilRelatorio: (state) => state.user.perfilRelatorio
    }),
    itemMenuInterno() {
      return this.$router.options.routes[0].children;
    },
    perfilUsuario() {
      return store.state.user.perfilUsuario;
    },
    menuAtivo() {
      return store.state.menuAtivado;
    },
  },
  methods: {
    menuCadastro(linkPath) {
      return linkPath.indexOf('cadastro') != -1
    },
    menuProcessamento(linkName) {
      return linkName.indexOf('Processar') != -1
    },
    menuDropDown(menu) {
      this.$refs[menu].classList.toggle("show");
    },
    toggleMenu() {
      return store.commit("SET_SIDEBAR", !this.menuAtivo);
    },
  }
};
</script>

<style lang="scss">
#sidebar {
  height: 100%;

  .container-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    transition: 0.3s;

    >div {
      position: relative;
      overflow-x: hidden;
      min-height: 100%;

      &:after {
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 53px;
        content: "";
        transition: 0.3s;
      }

      .container-siderbar-itens {
        overflow: auto;
        box-sizing: border-box;
        min-height: 100%;
        padding: 25px 0 70px 0;
      }
    }
  }

  .menu-lateral-btn {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 50%;
    width: 35px;
    height: 35px;
    min-height: 35px;
    cursor: pointer;
    transform: translateX(-50%);

    span {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 5px;
      transition: background 0.3s;
      transform: translateY(-50%);
      border-radius: 8px;

      &:before,
      &:after {
        position: absolute;
        left: -3px;
        width: 60%;
        height: 5px;
        content: "";
        transition: 0.3s;
        border-radius: 8px;
      }

      &:before {
        top: -6px;
        transform: rotate(-45deg);
      }

      &:after {
        top: 6px;
        transform: rotate(45deg);
      }
    }

    &.menu-lateral-fechado span {

      &:before,
      &:after {
        left: 17px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

/* Menu */
.menu {
  position: absolute;
  width: 270px;

  .titulo-drop {
    margin-left: 22px;
  }
}

.processamento,
.campanhas {
  .submenu {
    display: none;
    font-size: 20px;

    .link-sidebar {
      background: #eff3f8;
      padding: 0;
      margin: 0;
    }
  }

  &.show {
    .seta::before {
      top: 0.15em;
      transform: rotate(-45deg);
    }

    .submenu {
      display: block;
    }
  }
}

.drop-menu {
  cursor: pointer;
}

.seta {
  position: relative;
  z-index: 1;
  display: block;
  width: 40px;
  min-width: 40px;

  &:before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
    left: 0.25em;
    color: #f7941d;
    transform: rotate(135deg);
    transition: all 0.3s ease;
  }
}

.link-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;
  min-height: 40px;
  height: 40px;
  margin: 5px 0;
  transition: 0.3s;
  text-decoration: none;
  color: $preto-1;
  left: -5px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 60px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 0 18px 18px 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &:after {
    position: absolute;
    top: 0;
    left: 65px;
    visibility: hidden;
    width: 205px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 18px 0 0 18px;
  }

  .link-icone {
    position: relative;
    z-index: 1;
    display: block;
    width: 60px;
    min-width: 60px;

    svg,
    img {
      display: block;
      max-width: 22px;
      height: auto;
      max-height: 40px;
      margin: 0 20px;
    }

    .elementos-internas {
      transition: 0.3s;
    }
  }

  .link-icone-submenu {
    width: 20px;
  }

  .link-texto {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 190px;
    transition: 0.3s;
    text-align: left;
    white-space: nowrap;
    color: $cinza-1;
  }

  &:hover {
    .seta::before {
      color: #fff;
    }

    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .link-texto {
      padding-left: 10px;
    }
  }

  &.active {
    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    &:after {
      visibility: visible;
      opacity: 1;
    }

    .link-texto {
      padding-left: 20px;
    }
  }
}
</style>