<template>
  <router-link class="link-sidebar" :class="linkAtivo == link ? 'active' : ''" :to="link">
    <span class="link-icone">
      <component :is="icone" />
    </span>
    <span class="link-texto">{{ texto }}</span>
  </router-link>
</template>

<script>
import IconInicio from '@/components/elementos/logos/IconInicio.vue';
import IconCupom from '@/components/elementos/logos/IconCupom.vue';
import IconClientes from '@/components/elementos/logos/IconClientes.vue';
import IconProduto from '@/components/elementos/logos/IconProduto.vue';
import IconPromocao from '@/components/elementos/logos/IconPromocao.vue';
import IconUsuarios from '@/components/elementos/logos/IconUsuarios.vue';
import IconFaleConosco from "@/components/elementos/logos/IconFaleConosco.vue";
import IconRelatorioResgate from "@/components/elementos/logos/IconRelatorioResgate.vue";
import IconSac from "@/components/elementos/logos/IconSac.vue";
import IconBanner from "@/components/elementos/logos/IconBanner.vue";
import IconRanking from "@/components/elementos/logos/IconRanking.vue";
import IconProcessamento from "@/components/elementos/logos/IconProcessamento.vue";
import IconEspelho from "@/components/elementos/logos/IconEspelho.vue";
import IconBannerHome from "@/components/elementos/logos/IconBannerHome.vue";
import IconPerformance from "@/components/elementos/logos/IconPerformance.vue";
import IconTreinamento from "@/components/elementos/logos/IconTreinamento.vue";
import IconCampanha from '@/components/elementos/logos/IconCampanha.vue';
import IconNotificacao from '@/components/elementos/logos/IconNotificacao.vue';
import IconProcessarCampanha from '@/components/elementos/logos/IconProcessarCampanha.vue';
import IconPremios from '@/components/elementos/logos/IconPremios.vue';
import IconConfig from '@/components/elementos/logos/IconConfig.vue';

export default {
  props: ["texto", "link", "icone"],
  components: {
    IconInicio,
    IconCupom,
    IconClientes,
    IconProduto,
    IconPromocao,
    IconUsuarios,
    IconFaleConosco,
    IconRelatorioResgate,
    IconBanner,
    IconRanking,
    IconProcessamento,
    IconEspelho,
    IconBannerHome,
    IconPerformance,
    IconTreinamento,
    IconCampanha,
    IconNotificacao,
    IconProcessarCampanha,
    IconPremios,
    IconSac,
    IconConfig
  },
  data() {
    return {
      linkAtivo: "",
    };
  },
  mounted() {
    this.linkAtivo = `/${this.$router.history.current.path.split("/")[1]}`;
  },
  watch: {
    $route(val) {
      this.linkAtivo = `/${val.path.split("/")[1]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.link-sidebar {
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
  height: 40px;
  margin: 5px 0;
  transition: 0.3s;
  text-align: center;
  text-decoration: none;
  color: $preto-1;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 60px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 0 18px 18px 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &:after {
    position: absolute;
    top: 0;
    left: 65px;
    visibility: hidden;
    width: 205px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 18px 0 0 18px;
  }

  .link-icone {
    position: relative;
    z-index: 1;
    display: block;
    width: 60px;
    min-width: 60px;

    svg,
    img {
      display: block;
      max-width: 22px;
      height: auto;
      max-height: 40px;
      margin: 0 20px;
    }

    .elementos-internas {
      transition: 0.3s;
    }
  }

  .link-texto {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 190px;
    transition: 0.3s;
    text-align: left;
    white-space: nowrap;
    color: $cinza-1;
  }

  &:hover {
    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .link-texto {
      padding-left: 10px;
    }
  }

  &.active {
    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    &:after {
      visibility: visible;
      opacity: 1;
    }

    .link-texto {
      padding-left: 20px;
    }
  }
}
</style>
